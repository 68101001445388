<ng-container>
  <div
    *ngIf="this.stats$ | async as s"
    matTooltip
    [matTooltipTemplate]="statsTemplate"
    [matTooltipTemplateContext]="{ stats: s }"
  >
    <div class="mat-circle-card">
      <mat-progress-spinner
        [value]="s.recordedPercentage"
        strokeWidth="3"
        [diameter]="20"
        [color]="s.exceeded ? 'warn' : 'accent'"
        class="with-back-spinner"
      ></mat-progress-spinner>
    </div>
  </div>
</ng-container>
<ng-template #statsTemplate let-stats="stats">
  <div class="flex flex-row items-center space-x-1">
    <span>{{ stats.recorded | parseMsAsDuration: false }}</span>
    <span>{{ 'task.estimation-header-of' | translate }}</span>
    <span>{{ stats.estimation | parseMsAsDuration: false }}</span>
    <span>({{ stats.recordedPercentageAsString }}%)</span>
  </div>
</ng-template>

import { Component, OnInit, Input } from '@angular/core';
import { differenceInMinutes } from 'date-fns/esm';
import { addSeconds, differenceInSeconds } from 'date-fns/esm/fp';
import { flow } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-estimation-statistics-graph',
  templateUrl: './estimation-statistics-graph.component.html',
  styleUrls: ['./estimation-statistics-graph.component.scss'],
})
export class EstimationStatisticsGraphComponent implements OnInit {
  private _recorded = new BehaviorSubject<number>(null);
  readonly recorded$ = this._recorded.asObservable().pipe(distinctUntilChanged());
  get recorded() {
    return this._recorded.getValue();
  }
  @Input()
  set recorded(val: number) {
    this._recorded.next(val);
  }

  private _estimation = new BehaviorSubject<number>(null);
  readonly estimation$ = this._estimation.asObservable().pipe(distinctUntilChanged());
  get estimation() {
    return this._estimation.getValue();
  }
  @Input()
  set estimation(val: number) {
    this._estimation.next(val);
  }

  private _completed = new BehaviorSubject<boolean>(null);
  readonly completed$ = this._completed.asObservable().pipe(distinctUntilChanged());
  get completed() {
    return this._completed.getValue();
  }
  @Input()
  set completed(val: boolean) {
    this._completed.next(val);
  }

  get percentage() {
    const r = (this.recorded / this.estimation) * 100;
    return r > 100 ? 100 : r < 0 ? 0 : r;
  }
  constructor() {}

  ngOnInit() {}
  readonly stats$ = combineLatest([this.recorded$, this.estimation$, this.completed$]).pipe(
    filter(([recorded, estimation]) => recorded >= 0 && estimation >= 0),
    debounceTime(50),
    map(([_recorded, _estimation, _completed]) => {
      if (_recorded == null || _estimation == null) return null;
      const recorded = Math.floor(~~_recorded.clamp(0.0, ~~_recorded)),
        estimation = Math.floor(~~_estimation).clampThis(0.0);
      const recordedPercentage = ~~((~~recorded / ~~estimation) * 100);

      const recordedHours = flow(addSeconds(recorded), differenceInSeconds(Date.now()))(new Date()) / 60 / 60,
        estimationHours = flow(addSeconds(estimation), differenceInSeconds(Date.now()))(new Date()) / 60 / 60,
        diff = flow(addSeconds(estimation), differenceInSeconds(Date.now() - recorded * 1000.0))(new Date()) / 60 / 60;
      return {
        recorded,
        estimation,
        exceeded: differenceInMinutes(Date.now() + recorded * 1000, Date.now() + estimation * 1000) > 0,
        recordedPercentage: recordedPercentage,
        recordedPercentageAsString: recordedPercentage.toFixed(0),
        diff: diff >= 0.0 ? diff : 0,
        recordedHours: recordedHours >= 0.0 ? recordedHours : 0,
        estimationHours: estimationHours >= 0.0 ? estimationHours : 0,
      };
    })
  );
}
